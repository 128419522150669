import { fetchApi } from 'Utils/utils';

const URL_PREFIX = '/api/v1/reports/customerOrder';

const customerOrderCountReport = function(params) {
  return fetchApi.post(`${URL_PREFIX}/count/`, params);
};

const customerOrderDetailedReport = function(params) {
  return fetchApi.post(`${URL_PREFIX}/details/`, params);
};

export { customerOrderCountReport, customerOrderDetailedReport };
